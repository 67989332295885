









































import {
  Component, Prop, PropSync, Vue,
} from 'vue-property-decorator';
import InviteFriendSuccessViewModel
  from '@/vue-app/view-models/components/home-dashboard/invite-friend/invite-friend-success-view-model';

@Component({ name: 'InviteFriendSuccess' })
export default class InviteFriendSuccess extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @Prop(String) email?: string;

  invite_friend_success_view_model = Vue.observable(new InviteFriendSuccessViewModel(this));

  destroyed() {
    this.invite_friend_success_view_model.reloadInvitationsSent();
  }

  closeModal() {
    this.invite_friend_success_view_model.clearEmailInput();
    this.synced_is_open = false;
  }
}
