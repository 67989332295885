





























































import { Component, PropSync, Vue } from 'vue-property-decorator';

import InviteFriendModalViewModel
  from '@/vue-app/view-models/components/home-dashboard/invite-friend/invite-friend-modal-view-model';
import InviteFriendSuccess
  from '@/vue-app/components/home-dashboard/invite-friend/InviteFriendSuccess.vue';
import InviteFriendError
  from '@/vue-app/components/home-dashboard/invite-friend/InviteFriendError.vue';

@Component({
  name: 'InviteFriendModal',
  components: {
    InviteFriendSuccess,
    InviteFriendError,
  },
})
export default class InviteFriendModal extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

   invite_friend_modal_view_model = Vue.observable(new InviteFriendModalViewModel());

   async closeInviteFriendModal() {
     this.synced_is_open = false;
   }
}
