







































import { Component, PropSync, Vue } from 'vue-property-decorator';
import InviteFriendErrorViewModel
  from '@/vue-app/view-models/components/home-dashboard/invite-friend/invite-friend-error-view-model';

@Component({ name: 'InviteFriendError' })
export default class InviteFriendError extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  invite_friend_error_view_model = Vue.observable(new InviteFriendErrorViewModel(this));

  closeModal() {
    this.invite_friend_error_view_model.clearEmailInput();
    this.synced_is_open = false;
  }
}
