import TYPES from '@/types';
import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class InviteFriendSuccessViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  private readonly view: Vue;

  i18n_namespace = 'components.home-dashboard.invite_friend_success';

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  clearEmailInput = () => {
    this.view.$emit('reset');
    this.view.$emit('closeInviteFriendModal');
  }

  reloadInvitationsSent = () => {
    const reload_invitations_sent = new CustomEvent('reload.invitations.sent', { detail: { } });
    window.dispatchEvent(reload_invitations_sent);
  }
}
